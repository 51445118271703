import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import * as R from 'ramda'
import SEO from '@/components/SEO/SEO'
import { relatedAudio, optimisedImage } from '@/queries' // eslint-disable-line
import Shows from '@/shared/Shows'
import ShowList from '@/shared/ShowList'
import RelatedContent from '@/shared/RelatedContent'
import { prepRelatedContent, prepItem, getSeoImage } from '@/utils'

export default class RequestShow extends Component {
	render() {
		const {
			data: {
				craft: { entry, related: tmpRelated, list }
			},
			pageContext,
			location
		} = this.props

		const episodes = prepItem(list)

		const related = R.compose(
			R.take(8),
			prepRelatedContent
		)(tmpRelated)

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription,
			subtitle
		} = entry
		const seoImage = getSeoImage(entry)

		return (
			<Fragment>
				<SEO
					title={seoTitle || entry.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || entry.title}
					socialDesc={socialMetaDescription || subtitle}
					image={seoImage}
					url={location.href}
				/>
				<Shows
					entry={entry}
					pageContext={pageContext}
					url={location.href}
					render={data => (
						<ShowList {...data} entry={entry} episodes={episodes} />
					)}
				/>
				<RelatedContent related={related} />
			</Fragment>
		)
	}
}

export const pageQuery = graphql`
	query RequestShowByUri($uri: String!, $searchTags: String!, $id: [Int]!) {
		craft {
			entry: entry(uri: $uri) {
				... on Craft_TheRequestShow {
					title
					id
					subtitle
					postDate
					uri
					episodeNumber
					tags {
						title
						id
					}
					body {
						content
					}
					image {
						...optimisedImage
					}

					seoTitle
					seoDescription
					socialMetaTitle
					socialMetaDescription
					socialMetaImage {
						... on Craft_ImagesVolume {
							optimisedImage {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}

					bannerImage {
						title
						... on Craft_ImagesVolume {
							optimisedBanner {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}
					transcript {
						content
					}
					mediaSource
					duration
				}
			}

			list: entries(
				section: [theRequestShow]
				limit: 10
				order: "postDate desc"
			) {
				... on Craft_TheRequestShow {
					title
					uri
					id
					postDate
					duration
					mediaSource
					episodeNumber
				}
			}

			related: tagsConnection(search: $searchTags) {
				edges {
					relatedEntries(
						section: [blog, audio, video]
						limit: 9
						idNot: $id
						order: "postDate desc"
					) {
						entries {
							...relatedBlog
							...relatedAudio
							...relatedVideo
						}
					}
				}
			}
		}
	}
`

/*



*/
